import React from 'react';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';

const FourOhFourPage = ({ data }) => {
	return (
		<Layout>
			<Helmet>
				<title>GridZeroSolar | 404</title>
			</Helmet>
			<h1>404</h1>
		</Layout>
	);
};

export default FourOhFourPage;
